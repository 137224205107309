.footer {
  background-color: #1b0a2f;
  padding: 20px;
  text-align: center;
  font-size: 20px;
  .icon-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    .heart-icon,
    .me {
      color: #6518bf;
      margin-right: 10px;
    }
    .yuste {
      color: white;
    }

    .icon {
      position: relative;
      color: #fff;
      font-size: 24px;
      text-decoration: none;

      .tooltip {
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%) translateY(10px);
        background-color: #333;
        color: #fff;
        padding: 5px 10px;
        border-radius: 5px;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s, transform 0.3s;
        white-space: nowrap;
      }
    }

    .icon:hover .tooltip {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
}




