.App {
  text-align: center;
}


.App-header {
  background-color: #6518bf;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0px 20px;
}
.App-header .nav-buttons {
  display: flex;
}
.App-header .nav-buttons .nav-button {
  margin: 0px 10px;
}

.App-link {
  color: #61dafb;
}
.hero-image {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./assets/img/enie3banner.png');

  /* Set a specific height */
  height: 50%;
  min-height: 75px;
  margin: 10px 0px;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
@media (min-width: 1024px) {
  .hero-image {
    min-height: 175px;
    margin: 50px 0px;
  }
}

@media (min-width: 1500px) {
  .hero-image {
    min-height: 275px;
    margin: 50px 0px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
