.close-modal {
  position: absolute;
  left: 95%;
  top: 0px;
  border-radius: 25px;
  padding: 8px 11px;
  background-color: blueviolet;
  color: white;
  font-weight: 900;
  font-size: 25px;
  cursor: pointer;
}