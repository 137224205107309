.game-card-side-a,
.game-card-side-b {
  width: 95%;
  margin: 10px;
  border: 1px solid #6518bf;
  height: 250px;
  color: #fff;
  position: relative;
  -webkit-box-shadow: 3px 3px 5px 0px rgba(137,80,201,1);
  -moz-box-shadow: 3px 3px 5px 0px rgba(137,80,201,1);
  box-shadow: 3px 3px 5px 0px rgba(137,80,201,1);
  &__image-container {
    display: flex;
    &__image{
      width: 69%;
      img {
        width: 235px;
        height: 174px;
        cursor: pointer;
      }
    }
    &__genre-list {
      width: 27%;
      font-size: 13px;
      text-align: left;
      padding: 0;
      margin-left: 25px;
      .genre-list__item {
        margin: 3px 0px;
      }
    }
    &__icon {
      position: absolute;
      color: #ef94f9;
      background-color: #222325;
      top: 18px;
      left: 82%;
      font-size: 26px;
      cursor: pointer;
    }
    &__steam-icon {
      position: absolute;
      color: white;
      bottom: 18px;
      left: 88%;
      font-size: 40px;
      cursor: pointer;
    }
    &__shop-icon {
      position: absolute;
      color: white;
      bottom: 18px;
      left: 75%;
      font-size: 40px;
      cursor: pointer;

    }
  }



}
.game-card-side-a {
  display: flex;
  flex-direction: column;
  .side-a-title {
    position: absolute;

  }
}
.game-card-side-b {
  display: flex;
  .data-info-container {
    width: 55%;
    .title-container {
      margin: 14px 0px;
      .game-card-side-b__title {
        margin: 0px;
      }
    }
    .info-container {
      display: flex;
      .field-name,
      .field-info{
        margin: 12px;
        font-size: 12px;
      }
      .platform-list {
        list-style: none;
        display: flex;
        margin: 8px 0px;
        padding: 0px;
        li {
          margin: 0px 3px;
        }
      }
    }
  }
}
.demo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  .demo-link {
    color: #812EE6FF;
    font-size: 30px;
    margin: auto;
    cursor: pointer;
  }
}

@media (min-width: 550px ) {
  .game-card-side-a,
  .game-card-side-b {
    width: 60%;
    margin: 5px auto;
    &__image-container {
      &__image {
        img {
          width: 245px;
          height: 187px;
        }
      }
    }

  }

}
@media (min-width: 800px ) {
  .game-card-side-a,
  .game-card-side-b {
    width: 40%;
    margin: 5px auto;
    &__image-container {
      &__image {
        img {
          width: 245px;
          height: 187px;
        }
      }
    }

  }

}

@media (min-width: 1200px ) {
  .game-card-side-a,
  .game-card-side-b {
    width: 30%;
    margin: 5px auto;
    &__image-container {
      img {
        width: 255px;
        height: 187px;
      }
    }

  }

}
@media (min-width: 1500px ) {
  .game-card-side-a,
  .game-card-side-b {
    width: 30%;
    margin: 5px auto;
    &__image-container {
      img {
        width: 268px;
        height: 187px;
      }
    }

  }

}
@media (min-width: 1700px ) {
  .game-card-side-a,
  .game-card-side-b {
    width: 24%;
    &__image-container {
      &__image {
        img {
          width: 285px;
          height: 187px;
        }
      }
    }

  }
}

@media (min-width: 2200px ) {
  .game-card-side-a,
  .game-card-side-b {
    &__image-container {
      &__image {
        img {
          width: 320px;
          height: 187px;
        }
      }
    }

  }
}